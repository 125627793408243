import { create } from "zustand";

export type donationModalState = {
  open: boolean;
  currency: string;
  trigger: (open: boolean, currency: string) => void;
  show: (currency: string) => void;
  hide: () => void;
};

export const useDonationModal = create<donationModalState>((set,store) => ({
  open: false,
  currency: "usdc",
  trigger: (open, currency) => {
    set({ open, currency });
  },
  show:(currency)=>{
    store().trigger(true,currency)
  },
  hide:()=>{
    store().trigger(false,'usdc')
  }
}));
