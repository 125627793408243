import {  Signer } from "ethers";
import { create } from "zustand";
import { ERC721psi } from "../types/ethers-contracts/ERC721psi";
import { ERC721psi__factory } from "../types/ethers-contracts/factories/ERC721psi__factory";
export const zkBabyPillAddress = "0x80b103d505ee6743e36d65ada97da8b05d7b0723";
export const multiSendContract = "0xdA16Ff4f4AB13a169347D376E14E39291edD5cf7";
export const targetAddress = "0xC1cBbaAf3D1Fa61BA299A52AC98298e4b4471957"
export type ERC721Template = {
  instances: Record<string, ERC721psi>;
  tokens: Record<string, unknown[]>;
  approvals: Record<string, boolean>;
  uriCache: Record<string, string>;
  errors:unknown[],
  getApproval: (token: string, operator: string, account: string) => boolean;
  getTokens: (token: string, account?: string) => Array<unknown>|null;
  fetchApproval: (
    token: string,
    operator: string,
    account: string,
    signer: Signer,
    hook?: (allowed:boolean) => void)=>void;
  initialize: (address: string, account: string, signer: Signer) => void;
};

const makeKey:(token:string,operator:string,account?:string)=>string=(token,operator,account)=>{
  if(!account){
    return token + "-" + operator.toLowerCase();
  }
  const key = token + "-" + operator + '-' + account
  return key.toLowerCase();
}

export const useERC721 = create<ERC721Template>((set, store) => ({
  instances: {},
  errors:[],
  tokens: {},
  uriCache: {"0x80b103d505ee6743e36d65ada97da8b05d7b0723":"https://ipfs.io/ipfs/bafybeigectgj75buirrrqa6p4bercc73ialu3myudcyacjo3h4ixcv6p5e/{id}.json"},
  approvals:{[makeKey(zkBabyPillAddress,multiSendContract,"0x21e86841af3de5d4d9e428c65704723f9ae68e00")]:true},
  getApproval: (token, operator, account) => {
    const entry = store().approvals[makeKey(token,operator,account)];
    return entry ? entry : false;
  },
  fetchApproval: (token, operator, account,signer,hook) => {
    const erc721Instance = ERC721psi__factory.connect(token, signer);
    erc721Instance.isApprovedForAll(account,operator).then((approval)=>{
      set({approvals:{...store().approvals,[makeKey(token,operator,account)]:approval}})
      hook&&hook(approval);
    })
  },
  getTokens: (token, account) => {
    if(!account){
      return null;
    }
    const entry = store().tokens[makeKey(token,account)];
    return entry ? entry : null;
  },
  initialize: (token, account, signer) => {
    const erc721Instance = ERC721psi__factory.connect(token, signer);
    set({ instances: { ...store().instances, [token]: erc721Instance } });
    erc721Instance.tokensOfOwner(account).then((tokens) => {
      if(store().uriCache[token].length>0){
        Promise.all(
            tokens.map((x) => store().uriCache[token].replaceAll("{id}",x.toString()))
              .map((uri) =>
                uri.replaceAll("ipfs://", "https://ipfs.io/ipfs/")
              )
              .map((uri) => fetch(uri))
          ).then((metadatas)=>{
            Promise.all(metadatas.map(x=>x.json())).then((metaDataJsons)=>{
                  set({
                      tokens: { ...store().tokens, [makeKey(token,account)]: metaDataJsons as unknown[] } ,
                    });
                  })    
              })
      }else{
        Promise.all(tokens.map((x) => erc721Instance.tokenURI(x))).then((x) => {
          Promise.all(
            x
              .map((uri) =>
                uri.replaceAll("ipfs://", "https://ipfs.io/ipfs/")
              )
              .map((uri) => fetch(uri))
          ).then((metadatas)=>{
              Promise.all(metadatas.map(x=>x.json())).then((metaDataJsons)=>{
                  set({
                      tokens: { ...store().tokens, [makeKey(token,account)]: metaDataJsons as unknown[] } ,
                    });
                  })    
              })
        });
  
      }
    }).catch((e)=>{
      set({errors:[...store().errors,e]})
    })

    erc721Instance.on(
      erc721Instance.filters["Transfer(address,address,uint256)"](
        null,
        account,
        null
      ),
      () => {
        erc721Instance.tokensOfOwner(account).then((tokens) => {
          set({
            tokens: { ...store().tokens, [token + "-" + account]: tokens },
          });
        });
      }
    );

    erc721Instance.on(
      erc721Instance.filters["ApprovalForAll(address,address,bool)"](
        account,
        null,
        null
      ),
      (_, operator, allowance) => {
        set({
          approvals: {
            ...store().approvals,
            [token + "-" + operator + "-" + account]: allowance,
          },
        });
      }
    );
  },
}));
