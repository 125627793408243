/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./TGE.css";
import { ConnectButton } from "../components/ConnectButton";
import { useTGE } from "../hooks/useTGE";
import {  useEthers, useSigner } from "@usedapp/core";
import {  formatUnits } from "ethers/lib/utils";
import { useDonationModal } from "../hooks/useDonationModal";
import mileStoneInProgressIcon from "../assets/activeBottle.svg";
import mileStoneCompleteIcon from "../assets/emptyBottle.svg";
import mileStoneNotStartedIcon from "../assets/fullBottle.svg";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { formatToken } from "../components/DonateModal";
import { Notifications } from "../components/Notifications";

export const milestoneIcon = (
  isCleared: boolean,
  mileStoneIndex: number,
  currentMilestone: number
) => {
  return isCleared
    ? mileStoneCompleteIcon
    : mileStoneIndex === currentMilestone
    ? mileStoneInProgressIcon
    : mileStoneNotStartedIcon;
};
export const milestoneStatus = (
  isCleared: boolean,
  mileStoneIndex: number,
  currentMilestone: number
) => {
  return isCleared
    ? "opened"
    : mileStoneIndex === currentMilestone
    ? "in progress"
    : "not opened";
};

export const raisedTokenName = "ZKCALM";
export const helperCurrencyName = "ETH";
export const stableCurrencyName = "USDC";
export const helperCurrencyIcon = (
  <IonImg style={{ width: 25, height: 25 }} src={"/assets/eth.png"} />
);
export const stableCurrencyIcon = (
  <IonImg style={{ width: 25, height: 25 }} src={"/assets/usdc.svg"} />
);
const TGEPage: React.FC = () => {
  const {
    milestones,
    getInstance,
    owner,
    currentMilestoneIndex,
    started,
    soldOut,
  } = useTGE();
  const { post } = useTransactionModal();
  const signer = useSigner();
  const instance = signer && getInstance(signer);
  const { account } = useEthers();
  const { show: showDonationModal } = useDonationModal();
  const milestoneStatuses = milestones.map((x) =>
    milestoneStatus(x.isCleared, x.milestoneId, currentMilestoneIndex)
  );
  if (milestones.length === 0) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>TGE</IonTitle>
            <IonButtons slot="end">
              <ConnectButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonProgressBar color="secondary" type="indeterminate" />
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>TGE</IonTitle>
          <IonButtons slot="end">
            <ConnectButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Notifications />
        <IonCard color="secondary">
          <div style={{ width: "100%", height: 40 }}></div>
          {!soldOut && <IonTitle>Open the Bottle</IonTitle>}
          {soldOut && <IonTitle color="secondary">SOLD OUT</IonTitle>}
          <div style={{ width: "100%", height: 40 }}></div>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            {!soldOut && (
              <IonCardTitle>
                Contribute Assets for {raisedTokenName}
              </IonCardTitle>
            )}
            {!soldOut && (
              <IonCardSubtitle>
                Make your contributions to {raisedTokenName} in{" "}
                {helperCurrencyName} OR {stableCurrencyName}to claim your
                allocation in {raisedTokenName}
              </IonCardSubtitle>
            )}
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonButtons slot="start">
                {
                  milestoneStatuses.filter(
                    (x) => x === "opened" || x === "in progress"
                  ).length
                }{" "}
                bottles opened
              </IonButtons>
              <IonButtons slot="end">
                <IonText color={"secondary"}>
                  {milestoneStatuses.filter((x) => x === "not opened").length}{" "}
                  bottles unopened
                </IonText>
              </IonButtons>
            </IonItem>
            <IonItem>
              {milestones.map(
                (
                  [
                    priceOfPeg,
                    usdcRaised,
                    usdcOfWethRaised,
                    wethRaised,
                    targetAmount,
                    totalUSDCRaised,
                    milestoneId,
                    isCleared,
                  ],
                  i
                ) => (
                  <span key={i}>
                    <IonImg
                      src={milestoneIcon(
                        isCleared,
                        milestoneId,
                        currentMilestoneIndex
                      )}
                    />
                  </span>
                )
              )}
            </IonItem>
            {milestones
              .filter((x) => x[6] !== 0)
              .reverse()
              .map(
                (
                  [
                    priceOfPeg,
                    usdcRaised,
                    usdcOfWethRaised,
                    wethRaised,
                    targetAmount,
                    totalUSDCRaised,
                    milestoneId,
                    isCleared,
                  ],
                  i
                ) => (
                  <IonCard
                    color={
                      currentMilestoneIndex === milestoneId
                        ? "light"
                        : undefined
                    }
                    key={i}
                  >
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div>
                            {milestoneStatus(
                              isCleared,
                              milestoneId,
                              currentMilestoneIndex
                            )}
                          </div>
                          <IonLabel>Bottle {milestoneId}</IonLabel>
                          <div>
                            {raisedTokenName} PRICE {formatUnits(priceOfPeg, 6)}
                          </div>
                        </IonCol>
                        <IonCol>
                          <IonRow>
                            {formatToken(usdcRaised, 6)} {stableCurrencyName}{" "}
                            Raised
                          </IonRow>
                          <IonRow>
                            {" "}
                            {formatToken(wethRaised, 18)} {helperCurrencyName}{" "}
                            Raised
                          </IonRow>
                          {targetAmount.sub(totalUSDCRaised).gt(0) && (
                            <IonRow>
                              {formatToken(
                                targetAmount.sub(totalUSDCRaised),
                                6
                              )}{" "}
                              left
                            </IonRow>
                          )}
                        </IonCol>
                        <IonCol>
                          <IonItem
                            color={
                              currentMilestoneIndex === milestoneId
                                ? "light"
                                : undefined
                            }
                          >
                            {stableCurrencyIcon}
                            <IonProgressBar
                              color="success"
                              value={
                                parseFloat(formatUnits(usdcRaised, 6)) /
                                parseFloat(formatUnits(totalUSDCRaised, 6))
                              }
                            />
                            {(
                              (100 * parseFloat(formatUnits(usdcRaised, 6))) /
                              parseFloat(formatUnits(targetAmount, 6))
                            ).toFixed(3)}
                            %
                          </IonItem>
                          <IonItem
                            color={
                              currentMilestoneIndex === milestoneId
                                ? "light"
                                : undefined
                            }
                          >
                            {helperCurrencyIcon}
                            <IonProgressBar
                              color="tertiary"
                              value={
                                parseFloat(formatUnits(usdcOfWethRaised, 6)) /
                                parseFloat(formatUnits(totalUSDCRaised, 6))
                              }
                            />
                            {(
                              (100 *
                                parseFloat(formatUnits(usdcOfWethRaised, 6))) /
                              parseFloat(formatUnits(targetAmount, 6))
                            ).toFixed(3)}
                            %
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonProgressBar
                          buffer={0}
                          value={
                            parseFloat(formatUnits(totalUSDCRaised, 6)) /
                            parseFloat(formatUnits(targetAmount, 6))
                          }
                          color="secondary"
                        ></IonProgressBar>
                      </IonRow>
                    </IonGrid>
                    <IonButtons slot="end"></IonButtons>
                    {!isCleared && currentMilestoneIndex === milestoneId && (
                      <IonItem>
                        <IonButton
                          disabled={!started || soldOut}
                          color="secondary"
                          onClick={() => {
                            showDonationModal("eth");
                          }}
                        >
                          Contribute {helperCurrencyName}
                        </IonButton>
                        <IonButton
                          disabled={!started || soldOut}
                          color="secondary"
                          onClick={() => {
                            showDonationModal("usdc");
                          }}
                        >
                          Contribute {stableCurrencyName}
                        </IonButton>
                        {!started && (
                          <IonChip color="secondary">
                            TGE has not started yet
                          </IonChip>
                        )}
                      </IonItem>
                    )}
                  </IonCard>
                )
              )}
            <IonItem>
              {!started && owner === account && (
                <IonButton
                  onClick={() => {
                    instance?.startSale();
                  }}
                >
                  Start TGE
                </IonButton>
              )}
              {soldOut && owner === account && (
                <IonButton
                  onClick={() => {
                    instance?.withdrawETH().then((tx) => {
                      post(tx, "Withdrawing ETH 💰", () => {
                        // console.log("on god this be alotta money doe");
                      });
                    });
                  }}
                >
                  Withdraw ETH
                </IonButton>
              )}
              {soldOut && owner === account && (
                <IonButton
                  onClick={() => {
                    instance?.withdrawUSDC().then((tx) => {
                      post(tx, "Withdrawing USDC 💰", () => {
                        // console.log("my life a movie fr fr");
                      });
                    });
                  }}
                >
                  Withdraw USDC
                </IonButton>
              )}
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default TGEPage;
