import { useEffect } from "react";
import { useNotification } from "../hooks/useNotifications";
import React from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonList,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

export const Notifications: React.FC = () => {
  const { notifications, close } = useNotification();
useEffect(()=>{
  if(notifications.length>0){
    setTimeout(()=>{
      close(0)
    },4000)
  }
},[notifications])
  return (
<IonList>
    {notifications.map((notification, i) => <IonItem onClick={()=>close(i)} color={notification.color} key={i}>
    <IonButtons slot='start'><IonButton > <IonIcon color={"dark"} icon={notification.icon}/></IonButton></IonButtons>
    <IonButtons slot='end'><IonButton > <IonIcon color={notification.color==='danger'?"dark":"danger"} icon={closeOutline}/></IonButton></IonButtons>
{notification.message}
    </IonItem>)
    }

</IonList>
);
};
