import { BigNumber, Signer } from "ethers";
import { create } from "zustand";
import { Erc20 } from "../types/ethers-contracts/Erc20";
import { Erc20__factory } from "../types/ethers-contracts/factories/Erc20__factory";

export type ERC20Template = {
  instances: Record<string, Erc20>;
  balances: Record<string, BigNumber>;
  approvals: Record<string, BigNumber>;
  initialize: (address: string,account:string, signer: Signer) => void;
  getApproval: (token: string, operator: string,account:string) => BigNumber;
  getBalance: (token: string, account:string) => BigNumber;
  fetchApproval: (
    token: string,
    operator: string,
    account: string,
    signer: Signer,
    hook?: (allowance: BigNumber) => void
  ) => void;
};

export const useERC20 = create<ERC20Template>((set, store) => ({
  instances: {},
  balances: {},
  approvals: {},
  getApproval: (token,operator,account) => {
    const entry = store().approvals[token+'-'+ operator+"-"+account];
    return entry ? entry : BigNumber.from(0);
  },
  getBalance: (token,account) => {
    const entry = store().balances[token+'-'+account];
    return entry ? entry : BigNumber.from(0);
  },
  fetchApproval: async (token, operator,account, signer, hook) => {
    const erc20Instance = Erc20__factory.connect(token, signer);
    const allowance = await erc20Instance.allowance(account, operator);
    set({
      approvals: {
        ...store().approvals,
        [token + "-" + operator+'-'+account]: allowance,
      },
    });
    hook && hook(allowance);
  },
  initialize: (token,account, signer) => {
    const erc20Instance = Erc20__factory.connect(token, signer);
    set({ instances: { ...store().instances, [token]: erc20Instance } });
    erc20Instance.balanceOf(account).then((balance) => {
      set({ balances: { ...store().balances, [token+'-'+account]: balance } });
    });
    
    erc20Instance.on(erc20Instance.filters["Approval(address,address,uint256)"](account,null,null),(_,operator,allowance)=>{
      set({
        approvals: {
          ...store().approvals,
          [token + "-" + operator+'-'+account]: allowance,
        },
      });
      })
  },
}));
