/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import { IonButton } from "@ionic/react";
import { BigNumber } from "ethers";
import { useEffect } from "react";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { useEthers, useSigner } from "@usedapp/core";
import { useERC20 } from "../hooks/useERC20";
import React from "react";
import { formatToken } from "./DonateModal";
export const ApproveERC20Button: React.FC<{
  contract: string;
  amount: BigNumber;
  operator: string;
  decimals: number;
}> = ({ contract, operator, amount, decimals }) => {
  const { account } = useEthers();
  const signer = useSigner();
  const { initialize } = useERC20();
  const { fetchApproval, instances,getApproval } = useERC20();
  const instance = instances[contract]||BigNumber.from(0);
  const { post: postTx } = useTransactionModal();
  const { post } = useNotification();
  const allowance = getApproval(contract,operator,account!);
  useEffect(() => {
    signer && contract && initialize(contract,account!, signer);
  }, [signer, contract, initialize]);
  useEffect(() => {
    account && signer && fetchApproval(contract,operator,account, signer);
  }, [account, instance, contract, initialize, operator]);
  return (
    <>
      {instance && allowance.lt(amount) && (
        <IonButton
          onClick={() => {
            instance
              .approve(operator, amount)
              .catch((message) => {
                post({ color: "danger", message: message.reason });
              })
              .then((tx) =>
                postTx(tx, "Approve", () => {
                  fetchApproval(contract, operator,account!, signer!);
                })
              );
          }}
        >
          Approve TGE to spend {formatToken(amount, decimals)}
        </IonButton>
      )}
    </>
  );
};
