import { useEffect, useRef } from "react";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { DonateModal } from "./DonateModal";
import React from "react";
import {
  IonModal,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
  IonCardContent,
  IonProgressBar,
  IonImg,
} from "@ionic/react";
import { ZkSync } from "@usedapp/core";
import { ethers } from "ethers";
// import { useTGE } from "../hooks/useTGE";

export const Modals: React.FC = () => {
  // const { initialize } = useTGE();
  const { post } = useNotification();
  const { transaction, clear, hook, name } = useTransactionModal();
  const isSuccess = false;
  const modal = useRef<HTMLIonModalElement>(null);
  // useEffect(() => {
  //   // const customHttpProvider = new ethers.providers.JsonRpcProvider(
  //   //   ZkSyncTestnet.rpcUrl!
  //   // );
  //   // initialize(customHttpProvider);
  // }, [chainId]);

  useEffect(() => {
    const customHttpProvider = new ethers.providers.JsonRpcProvider(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ZkSync.rpcUrl!
    );
    transaction &&
      customHttpProvider.waitForTransaction(transaction.hash, 4).then(() => {
        modal.current?.dismiss().then(() => {
          post({ color: "primary", message: name + " Transaction Complete" });
          clear();
          hook && hook();
        });
      });
  }, [transaction, clear, hook, isSuccess, post, name]);

  return (
    <>
      <DonateModal />
      {typeof transaction !== "undefined" && (
        <IonModal ref={modal} isOpen={typeof transaction != "undefined"}>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                {name}
                <IonCardSubtitle>
                  <IonItem
                    target="_new"
                    href={"https://zkscan.io/tx/" + transaction?.hash}
                  >
                    Transaction {transaction?.hash}
                  </IonItem>
                </IonCardSubtitle>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonProgressBar
                  color={isSuccess ? "favorite" : "primary"}
                  type={"indeterminate"}
                />
              </IonItem>
              {name==="Combining BabyPills"&&<IonImg src="/assets/zkchiller.png"/>}
            </IonCardContent>
          </IonCard>
        </IonModal>
      )}
    </>
  );
};
