/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
    IonAvatar,
  IonBadge,
  IonButtons,
  IonCard,
  IonCardContent,
  IonChip,
  IonIcon,
  IonImg,
  IonItem,
  IonProgressBar,
  IonText,
} from "@ionic/react";
import "./TGE.css";
import { AccountChip } from "../components/ConnectButton";
import 'firebase/firestore';
import { collection, query, where, getDocs, getFirestore, orderBy } from "firebase/firestore";
import { firebaseApp } from "../App";
import { ZkSync, useEthers } from "@usedapp/core";
import { arrowForward, gitMergeOutline } from "ionicons/icons";
import { BigNumber, ethers } from "ethers";
import { multiSendContract, targetAddress, zkBabyPillAddress } from "../hooks/useERC721";
import { Multisender__factory } from "../types/ethers-contracts";


const TxInfoItem:React.FC<{from:string,ids:string[],tx:string,to:string,amount:number}>=({from,ids,tx,amount})=> 
{
    const [valid,setValid]=useState<boolean|null>(null)
    useEffect(()=>{
    const customHttpProvider = new ethers.providers.JsonRpcProvider(
        ZkSync.rpcUrl
      );
      tx&&
        customHttpProvider.getTransaction(tx).then((txInfo) => {
            if(txInfo.to!==multiSendContract){
                setValid(false);
            }
            const txParsed = Multisender__factory.createInterface().parseTransaction(txInfo)
            const [tokenAddress,targets,tokenIds]=txParsed.args;
            if(txInfo.from.toLowerCase()===from.toLowerCase()&&tokenIds.every((x:BigNumber)=>ids.includes(x.toString())) && targets.every((x:string)=>x.toLowerCase()===targetAddress.toLowerCase())&& tokenAddress.toLowerCase()===zkBabyPillAddress.toLowerCase()){
                setValid(true);
            }

        }).catch(()=>{
            setValid(false);
        })
},[])

if(valid===null){
    return <IonItem>
        <IonProgressBar type='indeterminate'/>
        </IonItem>
}
if(valid===false){
    return <></>
}
return <IonItem target="_blank" href={'https://explorer.zksync.io/tx/'+tx}>
<IonIcon icon={gitMergeOutline}/>
<AccountChip account={from}/>
{ids.map((id: string)=><IonAvatar key={id}><IonImg  src={"https://ipfs.io/ipfs/bafybeibkk5u3xfeabpkdbzpxtq3mvcuphlqkytaulgzjz3ylxlrful2xu4/"+id+".png"}></IonImg></IonAvatar>)}
<IonIcon icon={arrowForward}/>

<IonButtons slot='end'>
<IonChip color='dark'>

<IonAvatar color='tertiary'>
<IonImg src='/assets/zkchiller.png'/>
</IonAvatar>
<IonBadge color='light'>
  {amount} x
  </IonBadge>
<IonText>

ZKCHILLER
</IonText>
</IonChip>
</IonButtons>
    </IonItem>
}

// Create a query against the collection.

const Combinations: React.FC<{all?:boolean}> = ({all}) => {
    const [combinationHistory,setCombinationHistory] =useState<any[]|null>(null)
    const {account}=useEthers();
 useEffect(()=>{
        const db = getFirestore(firebaseApp);
        if(!account&&!all){
            return;
        }
        const q = all?query(collection(db, "combinations"),orderBy("indexed","desc")):query(collection(db, "combinations"),orderBy("indexed","desc"), where("from", "==", (account||"").toLowerCase()));        
        getDocs(q).then((querySnapshot)=>{
            setCombinationHistory(querySnapshot.docs.map(x=>x.data()))
        });
    },[account])
    
    return (
    <>
      {combinationHistory!==null &&<>
       {combinationHistory.length>0&& <IonCard>
          <IonCardContent>
            {(combinationHistory).map((x,i)=><TxInfoItem key={i} {...x}/>)}
             </IonCardContent>
        </IonCard>}
        {/* {combinationHistory.length===0 &&<IonItem>
          No Combinations detected...
          </IonItem>}
          {combinationHistory.length===0 &&<IonItem target="_blank" href='https://discord.com/invite/pBcUAJmwcF'>
            if this is an error please  and create a ticket:
            <a href='#'>join the discord</a>
          </IonItem>} */}
      </>}
    </>
  );
};

export default Combinations;
