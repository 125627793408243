/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./TGE.css";
import { ConnectButton } from "../components/ConnectButton";
import { multiSendContract, targetAddress, useERC721, zkBabyPillAddress } from "../hooks/useERC721";
import {  useEthers, useSigner } from "@usedapp/core";
import { ApproveERC721Button } from "../components/ApproveERC721Button";
import { addSharp, removeSharp, sparkles, warningOutline } from "ionicons/icons";
import { Multisender__factory } from "../types/ethers-contracts";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { Notifications } from "../components/Notifications";
import { getFirestore, addDoc, collection, Timestamp } from "firebase/firestore";
import { firebaseApp } from "../App";
import Combinations from "./Combinations";
import { BigNumber } from "ethers";
import { ApproveERC20Button } from "../components/ApproveERC20Button";
export const getAttribute = (token: any, trait_type: string) =>
  token.attributes.find((x: { trait_type: string,value:string }) => x.trait_type === trait_type)?.value;

const MultiSender: React.FC = () => {
  const { account } = useEthers();
  const signer = useSigner();
  const { initialize, getApproval } = useERC721();
  const [selected, setSelected] = useState<string[]>([]);
  const { post: postTx } = useTransactionModal();
  const { post } = useNotification();
    
  const [contractType,setContractType]=useState<string>("ERC721");
  const [tokenAddress,setTokenAddress]=useState<string>("");
  const [amountsOrIds,setAmountsOrIds]=useState<BigNumber[]>([]);
  const [receivers,setReceivers]=useState<string[]>([]);
    function removeBrackets(value: string) {
        return value.replaceAll("[","").replaceAll("]","")
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonTitle>ZKChiller</IonTitle>
          <IonButtons slot="end">
            <ConnectButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Notifications/>
      <IonContent>
      <IonItem>
        <IonSelect value={contractType} onIonChange={({detail})=>{
            setContractType(detail.value)
        }}>
            <IonSelectOption value={"ERC721"}>NFT Token</IonSelectOption>
            <IonSelectOption value={"ERC20"}>ERC20 Token</IonSelectOption>
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonInput onIonChange={({detail})=>{
            if(!detail.value){
                return;
            }
            setTokenAddress(detail.value)
        }} placeholder={"TokenAddress"}/>
      </IonItem>

      <IonItem>
        <IonInput onIonChange={({detail})=>{
            if(!detail.value){
                return;
            }
            setAmountsOrIds(removeBrackets(detail.value).split(",").map(x=>BigNumber.from(x)))
        }} placeholder={contractType==="ERC721"?"Ids":"Amounts"}/>
      </IonItem>
      <IonItem>
        <IonInput onIonChange={({detail})=>{
            if(!detail.value){
                return;
            }
            setReceivers(removeBrackets(detail.value).split(","))
        }} placeholder={"Revievers"}/>
      </IonItem>
      {contractType=="ERC20"&&<ApproveERC20Button amount={amountsOrIds.reduce((a,b)=>a.add(b),BigNumber.from(0))} contract={tokenAddress} decimals={18} operator={multiSendContract} />}
      {contractType=="ERC721"&&<ApproveERC721Button  contract={tokenAddress} operator={multiSendContract} />}
        {signer && contractType && amountsOrIds&& receivers&&<IonButton color='secondary' expand="full" onClick={()=>{
            Multisender__factory.connect(multiSendContract,signer).multisendToken(tokenAddress,receivers,amountsOrIds).then((newTX)=>{
                postTx(newTX,"Multisending tokens",()=>{
                    //
                })
            }).catch((e)=>{
                post({color:"danger",message:e.message,icon:warningOutline})
            })
}}>
            Send
        </IonButton>}
      </IonContent>
    </IonPage>
  );
};

export default MultiSender;
