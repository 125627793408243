/* eslint-disable @typescript-eslint/no-empty-function */
import { BigNumber, Signer } from "ethers";
import { create } from "zustand";
import { TGE, UserStructOutput } from "../types/ethers-contracts/TGE";
import { TGE__factory } from "../types/ethers-contracts/factories/TGE__factory";
import type { Provider } from "@ethersproject/providers";

export type TGETemplate = {
  address: string;
  instance: TGE | undefined;
  milestones: ([
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    number,
    boolean
  ] & {
    priceOfPeg: BigNumber;
    usdcRaised: BigNumber;
    usdcOfEthRaised: BigNumber;
    ethRaised: BigNumber;
    targetAmount: BigNumber;
    totalUSDCRaised: BigNumber;
    milestoneId: number;
    isCleared: boolean;
  })[];
  userInfo: UserStructOutput | undefined;
  maxMilestones: number;
  currentMilestoneIndex: number;
  donatedThisMilestone: boolean;
  usdcAddress: string;
  owner: string | undefined;
  started: boolean;
  soldOut: boolean;
  listening: boolean;
  wethAddress: string;
  usersPerMilestone: UserStructOutput[][];
  leaderboard: Record<string, BigNumber>;
  getUserInfo: (address: string, signer: Signer) => void;
  initialize: (provider: Provider) => void;
  update: (provider: Provider) => void;
  getInstance: (signer: Signer) => TGE;
};
export const useTGE = create<TGETemplate>((set, store) => ({
  address: "0xa5021465a608bc9c95e7e2EEC661C67A43349F18",
  instance: undefined,
  milestones: [],
  userInfo: undefined,
  currentMilestoneIndex: 0,
  maxMilestones: 10,
  donatedThisMilestone: false,
  usdcAddress: "",
  wethAddress: "",
  soldOut: false,
  listening: false,
  started: false,
  owner: undefined,
  leaderboard: {},
  usersPerMilestone: [],
  getInstance: (signer) => {
    return TGE__factory.connect(store().address, signer);
  },

  getUserInfo: async (address, signer) => {
    const instance = TGE__factory.connect(store().address, signer);

    const userInfo = await instance.getUserDetails(address);
    const currentMilestoneIndex = await instance.currentMilestone();
    const donatedThisMilestone = await instance.contributedInMilestone(
      address,
      currentMilestoneIndex
    );
    set({ userInfo, donatedThisMilestone });

  },
  update: async (signer) => {
    const instance = TGE__factory.connect(store().address, signer);
    const maxMilestones = await instance.MAX_MILESTONE();
    const milestoneIndexes = Array(maxMilestones)
      .fill(null)
      .map((_, i) => i+1)
    const milestones = await Promise.all(
      milestoneIndexes.map((i) => {
        return instance.milestones(i);
      })
    );
    const currentMilestoneIndex = await instance.currentMilestone();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentMilestone = milestones.find(
      (x) => x.milestoneId === currentMilestoneIndex
    )!;
    const soldOut =
      currentMilestoneIndex == maxMilestones && currentMilestone.isCleared;
    set({
      currentMilestoneIndex,
      maxMilestones,
      milestones,
      soldOut,
    });
    const usersPerMilestone = await Promise.all(
      [...milestoneIndexes,maxMilestones].map((index,i) => {
        return instance.getUsersPerMilestone(i);
      })
    );
    const leaderboard = usersPerMilestone
      .flatMap((x) => x.map(({ user }) => ({ [user]: BigNumber.from(0) })))
      .reduce((a, b) => ({ ...a, ...b }), {});
    usersPerMilestone.forEach((x) =>
      x.forEach((x) => {
        leaderboard[x.user] = leaderboard[x.user].add(
          x.usdcOfEthDonations.add(x.usdcDonations)
        );
      })
    );
    set({
      leaderboard,
      usersPerMilestone,
    });


  },
  initialize: async (provider) => {
    const instance = TGE__factory.connect(store().address, provider);
    set({ instance });
    const maxMilestones = await instance.MAX_MILESTONE();
    const usdcAddress = await instance.usdc();
    const wethAddress = await instance.weth9();
    const owner = await instance.owner();
    const started = await instance.hasStarted();
    const milestoneIndexes = Array(maxMilestones)
      .fill(null)
      .map((_, i) => i+1)
    if (!store().listening) {
      instance.on("SaleStarted", () => {
        set({ started: true });
      });
      // instance.on("MilestoneAchieved", (e) => {
      // });
      instance.on("USDCContributed", () => {
        store().update(provider);
      });
      instance.on("ETHContributed", () => {
        store().update(provider);
      });
      set({ listening: true });
    }
    const milestones = await Promise.all(
      milestoneIndexes.map((i) => {
        return instance.milestones(i);
      })
    );
    const currentMilestoneIndex = await instance.currentMilestone();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentMilestone = milestones.find(
      (x) => x.milestoneId === currentMilestoneIndex
    )!;
    const soldOut =
      currentMilestoneIndex == maxMilestones && currentMilestone&&currentMilestone.isCleared;
    set({
      usdcAddress,
      wethAddress,
      currentMilestoneIndex,
      maxMilestones,
      milestones,
      started,
      owner,
      soldOut,
    });
    const usersPerMilestone = await Promise.all(
      [...milestoneIndexes,maxMilestones].map((index,i) => {
        return instance.getUsersPerMilestone(i);
      })
    );
    const leaderboard = usersPerMilestone
      .flatMap((x) => x.map(({ user }) => ({ [user]: BigNumber.from(0) })))
      .reduce((a, b) => ({ ...a, ...b }), {});
    usersPerMilestone.forEach((x) =>
      x.forEach((x) => {
        leaderboard[x.user] = leaderboard[x.user].add(
          x.usdcOfEthDonations.add(x.usdcDonations)
        );
      })
    );
    set({
      leaderboard,
      usersPerMilestone,
    });
  },
}));
