import {  IonButton, IonChip } from "@ionic/react";
import React, { useEffect } from "react";
import { ZkSync, useEthers} from "@usedapp/core";

export const AccountChip:React.FC<{account:string}> = ({account}) =>   <IonChip color={"secondary"}>
{account.slice(0,6)}...
{account.slice(account.length-6,account.length)}
</IonChip>


export const ConnectButton: React.FC = () => {
    const { account, activateBrowserWallet,chainId,switchNetwork } = useEthers()
  useEffect(()=>{
    chainId!==ZkSync.chainId
    account&&switchNetwork(ZkSync.chainId);
  },[chainId,account])
    return <>{typeof account !== "undefined" ? (
      <AccountChip account={account}/>
      ) : (
    <IonButton
      onClick={() => {
        activateBrowserWallet();
      }}
    >
      Connect
    </IonButton>
  )}
  
  </>
};
