/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./TGE.css";
import { ConnectButton } from "../components/ConnectButton";
import { multiSendContract, targetAddress, useERC721, zkBabyPillAddress } from "../hooks/useERC721";
import {  useEthers, useSigner } from "@usedapp/core";
import { ApproveERC721Button } from "../components/ApproveERC721Button";
import { addSharp, removeSharp, sparkles } from "ionicons/icons";
import { Multisender__factory } from "../types/ethers-contracts";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { Notifications } from "../components/Notifications";
import { getFirestore, addDoc, collection, Timestamp } from "firebase/firestore";
import { firebaseApp } from "../App";
import Combinations from "./Combinations";
export const getAttribute = (token: any, trait_type: string) =>
  token.attributes.find((x: { trait_type: string,value:string }) => x.trait_type === trait_type)?.value;

export const NFTCard: React.FC<{
  selected: boolean;
  token: any;
  onClick: () => void;
}> = ({ token, onClick, selected }) => {
  const color = getAttribute(token, "Body");
  const uiColor= color==="Purple"  ? "tertiary"  : color === "Pink"  ? "warning"  :color==="Blue"? "primary":"medium"

  return (
    <IonCol size="3">
      <IonCard color={selected ? uiColor : undefined} onClick={onClick}>
        <IonCardHeader color={selected ? uiColor : undefined}>
          <IonBadge
            color={selected?"light":uiColor}
          >
            {token.name}
          </IonBadge>
        </IonCardHeader>
        <IonCardContent>
          <IonImg
            src={token.image.replaceAll(
              "ipfs://",
              "https://ipfs.io/ipfs/"
            )}
          />
          <IonButton color="light" expand="full">
            <IonBadge
              color={uiColor
              }
            >
             <IonIcon icon={(selected ? removeSharp : addSharp)}/>
              {color||"Rainbow"} Pill
            </IonBadge>
          </IonButton>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

const isValid = (bodies: string[]):[boolean,number,string] => {
  const amounts:Record<string,number>={"Pink":0, "Purple":0, "Blue":0};
  if(bodies.length===0){
    return [false,0,"select 3 babypills one of each body color, and any number of rainbow pills"];
  }
  const rainbowAmount=bodies.filter(x=>typeof x==='undefined').length;
  if(rainbowAmount===bodies.length){
    return [true,bodies.length,"Rainbow Special"]
  }
  bodies.forEach((bodyColor) => {
    amounts[bodyColor]=amounts[bodyColor]+1;
  });
  if(amounts["Pink"]===amounts["Purple"]&&amounts["Blue"]===amounts["Pink"]){
    if(rainbowAmount>0){
      return [true,amounts["Pink"]+rainbowAmount,"Normal Combination +("+rainbowAmount+" rainbow)"];
    }else{
      return [true,amounts["Pink"],"Normal Combination"];
    }
  }else{
    const sorted=Object.entries(amounts).sort(([a,b],[c,d])=>(b-d));
    if(sorted.filter(([x,y])=>y>0).length===3){
      return [false,bodies.length,"too many "+sorted[2][0]+" pills"];
    }
    return [false,bodies.length,"not enough "+sorted[0][0]+" pills"];
  }


};
const Chiller: React.FC = () => {
  const { account } = useEthers();
  const signer = useSigner();
  const { initialize, getApproval } = useERC721();
  const [selected, setSelected] = useState<string[]>([]);
  const { post: postTx } = useTransactionModal();
  const { post } = useNotification();
  const approved =
    account && getApproval(zkBabyPillAddress, multiSendContract, account);
    useEffect(() => {
      signer && account && initialize(zkBabyPillAddress, account, signer);

    }, [account, signer, initialize]);
    const tokens = useERC721((x) =>x.getTokens(zkBabyPillAddress, account||""));
  if(tokens===null){
    return <IonPage>
      <IonToolbar color='secondary'>
                <IonTitle>ZKChiller</IonTitle>
      {account&&<IonProgressBar color='secondary' type="indeterminate"/>}
<IonButtons slot="end">
<ConnectButton/>
</IonButtons>
    </IonToolbar>
    <IonItem>
    <IonImg src='/assets/zkchiller.png'/>
    </IonItem>
    </IonPage>
  }
  const [valid,amount,error] = isValid(
    tokens
      .filter((x: any) => selected.includes(x.tokenId))
      .map((x) => getAttribute(x, "Body"))
  );
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonTitle>ZKChiller</IonTitle>
          <IonButtons slot="end">
            <ConnectButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
    <Combinations/>
      <Notifications/>
        <IonGrid>
          {tokens.length>0&&valid&&<ApproveERC721Button
            contract={zkBabyPillAddress}
            operator={multiSendContract}
          />}
          {tokens.length===0&&<IonItem target="_blank" href='https://omnisea.org/Ax6WEuU6G6VEtPl6v1ns'>
            <IonAvatar><IonImg src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPtSURBVHgB7ZpNUhpREIC7ByuWWZmNia7wBniC4AmUE6gnSG6gnsDkBNETgCeQnEBv4OwSXLFJZcV0uh8DjASEeT8zj6r+qqgaHv8f3fNe9zwARVEURVEURVEURVEUpWYQNojuL2onCCcE1OZv3gSC3fyhVG48fg+U9Dr7mEIANkKWSEKkSz5sr/UCpFvKkmvf0qKW1X2mXXwPN0B0DhYg4tXJR7wGT0Qri6OpydHU5cPW3EN9k26YpJDBEzQ4FUd8w6zFcs44NV8936ewKGXloh74sFkY7hOhpFb/zdcO6BSJboqv9SUsOlmLRFFG3zsHja+l3iPhqCxEGYs+XiV6FQlExEJRRNdlRAlyYqd3eAzjWdKQTxBORCNrqaj9xhVY0PmAQ46mi8JQW2ZVcCAKWd0XanHaPIInURPytOtPByg7BQdql2VEEUfUbIHJorILV1HT95KZMwcb+BkcqFXW/QudLRa1dQu+QEqnx/Rqdi3NFtSEiOJZ7nY6gDCkDDssqg8+yRpDFja5twsO1BJZLOrLf6LQfWpfgyE4ULms+wFdsqhvhaHUiNrDJwgBr+xnx7OlhA2VpqERRXRVGBqaxeJemC6BgIAns3vk9IdUFlkLRAm7Uv9JwQwBkNIHCp0K7kTcgQOVlDtLRBV5or8cYYfodE4psmCRm55+Sg7BgeCR1RvQjxWihBbu0KP8QPCAae2MOxbNyZikOzgSVJaIKtGLMpHgKsxE1I6pBgpFNHlpBAZJQ/PP7ph/tg3lSfMOQQol8V1fzuNdVi5KvnAL7CktLLQowWsa5ingKkoYpyTXjes8uQpRgrfIWtLddGVIydsLVlOIZ/Mnc/+iBC+yAomaIAvXzqJSaHnHwmMhXsA5DQOLEmQZ8CCF99zntqsUJTjJqkDUFCm8u79Hpr1sWjtYEIV52RRQ1PhjLKlS1Bw9vs06npOORahCvICVrHx58KoNXBMpTwCdKkQJVl2HZAcuKQZRgTsW85SOrDz9nqFerFf5Llic4EdtqJdaRAmlZSEmZ1AftYkSykcWOpcytkjP66guUUJ5WeR2hcQS781BG6La67AIrvPuYhAlWCwdJA38dDRXIaK4ID6HSLCJrEoWgGabUUSihNKyCLKfEBibbUZVUD6ytpNbqccgEKF6UT4oLcvse+IUgQDELEqw7jr0Btnj/GZXF2IXJVgvHWTHC4Db3oHpe3ncjxUSp7ayc09LelEjvOgcYA82AKdFqdnouo1HluewPkfn0aaIErxe3QHIrhDNVsQmLP60IZ/nntbZzx4jYa5Iy/U+2ZJIWdMMUDKEBi9m/0AaQ9miKIqiKIqiKIqiKIoSF/8AfOgwcDUpPmQAAAAASUVORK5CYII="}/></IonAvatar>
            You do not have any more babypills!
            mint is live on <IonBadge color='secondary'>omnisea</IonBadge>
            </IonItem>}
            {valid && (
            <IonItem>
              <IonIcon color='warning' icon={sparkles}/>
             {amount} VALID COMBINATION{amount>1?"S":""} <IonIcon color='warning' icon={sparkles} />  {error}
<IonButtons slot='end'>
  
             <IonAvatar color='tertiary'>
<IonImg src='/assets/zkchiller.png'/>
</IonAvatar>
<IonBadge color='light'>
  {amount} x
  </IonBadge>
  </IonButtons>

            </IonItem>
          )}
          {!valid && selected.length>0&& <IonBadge color='danger'>{error}</IonBadge>}
          {!valid && selected.length===0&&tokens.length>0&&<IonBadge color='secondary'>{error}</IonBadge>}
          {valid && approved && signer&&(
            <IonButton expand="full" color="secondary"  onClick={()=>{
              Multisender__factory.connect(multiSendContract,signer).multisendToken(zkBabyPillAddress,selected.map(()=>targetAddress),selected)
              .catch(({message}) => {
                post({ color: "danger", message: message });
              })
              .then((tx) =>
                tx&&postTx(tx, "Combining BabyPills", () => {
                  const db = getFirestore(firebaseApp);
                  addDoc(collection(db, "combinations"), {
                      from: account.toLowerCase(),
                      to: targetAddress.toLowerCase(),
                      ids: selected.map(x=>x.toString()),
                      tx: tx.hash,
                      amount:amount,
                      indexed: Timestamp.now() 
                    });
                  setSelected([])
                })
              );

            }}>
              Combine Babypills
            </IonButton>
          )}
                    
                    <IonRow>
            {tokens.map((token: any, i) => (
              <NFTCard
                selected={selected.includes(token.tokenId)}
                onClick={() => {
                  if (selected.includes(token.tokenId)) {
                    setSelected((x) => x.filter((x) => x !== token.tokenId));
                  } else {
                    setSelected((x) => [...x, token.tokenId]);
                  }
                }}
                key={i}
                token={token}
              />
            ))}
          </IonRow>

        </IonGrid>
        <IonItem>
          <IonImg src='/assets/zkchiller.png'/>
        </IonItem>
      
      </IonContent>
    </IonPage>
  );
};

export default Chiller;
