/* eslint-disable @typescript-eslint/no-empty-function */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDonationModal } from "../hooks/useDonationModal";
import { ApproveERC20Button } from "./ApproveERC20Button";
import { useTGE } from "../hooks/useTGE";
import { BigNumber, ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { useERC20 } from "../hooks/useERC20";
import { ZkSync, useEthers, useSigner } from "@usedapp/core";
import { helperCurrencyName, stableCurrencyName } from "../pages/TGEPage";
import { closeOutline, sadOutline } from "ionicons/icons";

const vigintillion = BigNumber.from(10).pow(63);
const quindecillion = BigNumber.from(10).pow(48);
const nonillion = BigNumber.from(10).pow(30);
const octillion = BigNumber.from(10).pow(27);
const septillion = BigNumber.from(10).pow(24);
const sextillion = BigNumber.from(10).pow(21);
const quadrillion = BigNumber.from(10).pow(15);
const trillion = BigNumber.from(10).pow(12);
const billion = BigNumber.from(10).pow(9);
const million = BigNumber.from(10).pow(6);
const thousand = BigNumber.from(10).pow(3);

export const formatToken = (ogBalance: BigNumber, decimals: number) => {
  if (typeof ogBalance === "undefined") {
    return "0";
  } else if (typeof ogBalance === "string") {
    return ogBalance;
  }

  if (!BigNumber.isBigNumber(ogBalance)) {
    throw "NOT A BIG NUMBER";
  }
  const balance = ogBalance.div(BigNumber.from(10).pow(decimals));

  if (balance.gte(vigintillion)) {
    return balance.div(vigintillion) + " vigintillion";
  } else if (balance.gte(quindecillion)) {
    return balance.div(quindecillion) + " quindecillion";
  } else if (balance.gte(nonillion)) {
    return balance.div(nonillion) + " nonillion";
  } else if (balance.gte(octillion)) {
    return balance.div(octillion) + " septillion";
  } else if (balance.gte(septillion)) {
    return balance.div(septillion) + " septillion";
  } else if (balance.gte(sextillion)) {
    return balance.div(sextillion) + " sextillion";
  } else if (balance.gte(quadrillion)) {
    return (
      balance.div(quadrillion) +
      "." +
      balance.mod(quadrillion).toString().slice(0, 2) +
      " Quadrillion"
    );
  } else if (balance.gte(trillion)) {
    return (
      balance.div(trillion) +
      "." +
      balance.mod(trillion).toString().slice(0, 2) +
      " Trillion"
    );
  } else if (balance.gte(billion)) {
    return (
      balance.div(billion) +
      "." +
      balance.mod(billion).toString().slice(0, 2) +
      " Billion"
    );
  } else if (balance.gte(million)) {
    return (
      balance.div(million) +
      "." +
      balance.mod(million).toString().slice(0, 2) +
      " million"
    );
  } else if (balance.gte(thousand)) {
    return balance.div(thousand) + " thousand";
  } else if (balance.eq(0)) {
    return formatUnits(ogBalance, decimals);
  } else {
    return balance.toString();
  }
};

export const DonateModal: React.FC = () => {
  const { currency, open, hide } = useDonationModal();
  const { account } = useEthers();
  const { post } = useTransactionModal();
  const signer = useSigner();
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0));
  const {
    address: TGEAddress,
    getInstance,
    usdcAddress,
    wethAddress,
  } = useTGE();
  const modal = useRef<HTMLIonModalElement>(null);
  const instance = signer && getInstance(signer);
  const currnecyAddress = currency === "usdc" ? usdcAddress : wethAddress;
  const heldAmount = useERC20((x) => x.getBalance(currnecyAddress, account||""));
  const { getApproval } = useERC20();
  const approvedAmount = getApproval(currnecyAddress, TGEAddress, account||"");
  const { initialize, instances } = useERC20();
  const [ethBalance, setEthBalance] = useState<BigNumber>(BigNumber.from(0));
  const decimals = currency === "usdc" ? 6 : 18;
  useEffect(() => {
    if (!signer) {
      return;
    }
    const customHttpProvider = new ethers.providers.JsonRpcProvider(
      ZkSync.rpcUrl
    );
    account &&
      customHttpProvider.getBalance(account).then((balance) => {
        setEthBalance(balance);
      });
  }, [signer]);
  useEffect(() => {
    const currencyInstance = instances[currnecyAddress];

    if (typeof currencyInstance === "undefined" && signer) {
      initialize(currnecyAddress, account||"", signer);
    }
  }, [account, currnecyAddress]);
  useEffect(() => {
    const currencyInstance = instances[currnecyAddress];

    if (typeof currencyInstance === "undefined" && signer) {
      initialize(currnecyAddress, account||"", signer);
    }
  }, [account, currnecyAddress]);

  return open ? (
    <IonModal
      ref={modal}
      isOpen={open}
      mode="md"
      onDidDismiss={() => {
        hide();
      }}
    >
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonCardTitle>Contribute {currency.toUpperCase()}</IonCardTitle>
            <IonButtons slot="end">
              <IonButton
                color="danger"
                onClick={() => {
                  modal.current?.dismiss();
                }}
              >
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          <IonInput
            min="0"
            type="number"
            placeholder="Enter amount..."
            onIonInput={({ detail }) => {
              detail.value !== ""?
                setAmount(
                  BigNumber.from(
                    parseInt(
                      (parseFloat(detail.value || "") * 100000).toFixed(0)
                    )
                  ).mul(BigNumber.from(10).pow(decimals - 5))
                ):setAmount(BigNumber.from(0));
            }}
          ></IonInput>
        </IonCardContent>
      </IonCard>
      {currency === "eth" && ethBalance.lt(amount) && amount.gt(0) && (
        <IonItem>
          <IonButtons slot="start">
            <IonIcon color="danger" icon={sadOutline} />
            <IonText>Not Enough ETH</IonText>
          </IonButtons>
        </IonItem>
      )}
      {(approvedAmount.gte(amount) ||
        (currency === "eth" && ethBalance.gt(amount))) &&
        amount.gt(BigNumber.from(0)) && (
          <IonButton
            color={"secondary"}
            onClick={() => {
              if (currency === "usdc") {
                instance?.contributeUSDC(amount).then((tx) => {
                  post(tx, "Contribute" + stableCurrencyName, () => {
                    modal?.current?.dismiss();
                  });
                });
              } else {
                instance?.contributeETH({ value: amount }).then((tx) => {
                  post(tx, "Contribute " + helperCurrencyName, () => {
                    modal?.current?.dismiss();
                  });
                });
              }
            }}
          >
            Contribute {formatToken(amount, decimals)} {currency}
          </IonButton>
        )}
      {heldAmount.gt(0) && currency === "usdc" && (
        <ApproveERC20Button
          decimals={decimals}
          contract={currnecyAddress}
          amount={amount}
          operator={TGEAddress}
        ></ApproveERC20Button>
      )}
      {currency === "usdc" && (
        <IonItem>{formatToken(heldAmount, decimals)}</IonItem>
      )}
      {currency !== "usdc" && (
        <IonItem
          onClick={() => {
            setAmount(ethBalance.sub(BigNumber.from("100000000000000000")));
          }}
        >
          {formatToken(ethBalance, 18)} in wallet
        </IonItem>
      )}
      {currency === "usdc" && (
        <IonItem
          onClick={() => {
            setAmount(approvedAmount);
          }}
        >
          approved:{formatToken(approvedAmount, decimals)}
        </IonItem>
      )}
    </IonModal>
  ) : (
    <></>
  );
};
