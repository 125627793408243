import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import {
  gitMergeOutline,
  listOutline,
  // listOutline,
} from "ionicons/icons";
// import HighScore from "./pages/HighScore";
// import PEG from "./pages/PEG";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
// import TGEPage from "./pages/TGEPage";

import { DAppProvider, Config, ZkSync } from "@usedapp/core";
import { Modals } from "./components/Modals";
import Chiller from "./pages/Chiller";

const config: Config = {
  readOnlyChainId: ZkSync.chainId,
  readOnlyUrls: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [ZkSync.chainId]: ZkSync.rpcUrl!,
  },
};

setupIonicReact();
import { initializeApp } from "firebase/app";
import Combinations from "./pages/Combinations";
import { ConnectButton } from "./components/ConnectButton";
import MultiSender from "./pages/MultiSender";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDTc8VNzIRNxAak0ssSV4HmkyTMJiuQNz0",
  authDomain: "chill-casino.firebaseapp.com",
  projectId: "chill-casino",
  storageBucket: "chill-casino.appspot.com",
  messagingSenderId: "933972170805",
  appId: "1:933972170805:web:4d6f908e4704b2045f397c",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const App: React.FC = () => (
  <IonApp>
    <DAppProvider config={config}>
      <Modals />
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            {/* <Route exact path="/tge">
              <TGEPage />
            </Route> */}
            <Route exact path="/">
              <Redirect to={"/chiller"} />
            </Route>
            {/* <Route exact path="/peg">
              <PEG />
            </Route> */}
            <Route exact path="/chiller">
              <Chiller />
            </Route>
            <Route exact path="/multisend">
              <MultiSender />
            </Route>
            <Route exact path="/combinations">
              <IonPage>
                <IonHeader>
                  <IonToolbar color="secondary">
                    <IonTitle>ZKChiller</IonTitle>
                    <IonButtons slot="end">
                      <ConnectButton />
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent>
                  <Combinations all />
                </IonContent>
              </IonPage>
            </Route>
            {/* <Route path="/score">
              <HighScore />
            </Route> */}
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            {/* <IonTabButton tab="tge" href="/tge">
              <IonIcon aria-hidden="true" icon={giftOutline} />
              <IonLabel>TGE</IonLabel>
            </IonTabButton>
            <IonTabButton tab="peg" href="/peg">
              <IonIcon aria-hidden="true" icon={walletOutline} />
              <IonLabel>PEG</IonLabel>
            </IonTabButton>
            <IonTabButton tab="score" href="/score">
              <IonIcon aria-hidden="true" icon={listOutline} />
              <IonLabel>High Score</IonLabel>
            </IonTabButton> */}
            <IonTabButton tab="chiller" href="/chiller">
              <IonIcon aria-hidden="true" icon={gitMergeOutline} />
              <IonLabel>Combine</IonLabel>
            </IonTabButton>
            <IonTabButton tab="activity" href="/combinations">
              <IonIcon aria-hidden="true" icon={listOutline} />
              <IonLabel>Activity</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactHashRouter>
    </DAppProvider>
  </IonApp>
);

export default App;
